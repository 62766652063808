import { useEffect, useState } from "react";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { Loader } from "../../../../MainComponents";

export default function GroupSessions() {
  const { groupID } = useParams();
  function ReOpenForm({ groupID }) {
    const [reopenStudent, setReopenStudent] = useState({
      flag: "",
      dependency: false,
      data: {},
    });

    const [reopenStudentSuccess, reopenStudentErrors, submitLoading] = useAxios(
      process.env.REACT_APP_ADMIN_LECTURES_GROUP_REOPEN_API,
      "POST",
      reopenStudent.flag,
      reopenStudent.dependency,
      reopenStudent.data,
      true
    );
    const [LectureData, LectureError, LectureLoading] = useAxios(
      `${process.env.REACT_APP_ADMIN_LECTURES_GROUP_GET_API}${groupID}`,
      "GET",
      "GET",
      ""
    );

    const {
      register,
      handleSubmit,
      formState: { errors, isValid },
      reset,
      setValue,
    } = useForm({
      mode: "onChange",
      defaultValues: {
        lecture_id: LectureData?.data[0]?.key,
      },
    });
    function onSubmit(data) {
      setReopenStudent({
        flag: "reopenStudent",
        dependency: !reopenStudent.dependency,
        data: {
          group_id: groupID,
          ...data,
        },
      });
      reset();
    }

    if (LectureLoading) {
      return <Loader />;
    }
    return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        method="post"
        className="sm:max-w-3/4 flex w-[450px] max-w-[450px]  flex-col items-start  justify-center rounded-3xl bg-white  p-16 shadow-3xl  sm:w-3/4"
      >
        <div className=" flex w-full flex-col items-end justify-center gap-2">
          <label htmlFor="n_day">Number Of Days</label>
          <input
            placeholder="Number Of Days"
            className="signin-inputs w-full "
            type="text"
            id="n_day"
            name="n_day"
            autoComplete="on"
            {...register("n_day", {
              required: true,
              minLength: 1,
            })}
          />
          {errors.n_day && (
            <p className="text-[12px] text-blue-900 ">
              {errors.n_day.type === "required" &&
                "Please enter number of days"}
            </p>
          )}
          {
            //!-------server errors -----

            reopenStudentErrors &&
              reopenStudentErrors?.response?.data?.n_day && (
                <p className="w-full  text-[12px] text-blue-900  ">
                  {reopenStudentErrors?.response?.data?.n_day}
                </p>
              )
          }
        </div>
        <div className=" flex w-full flex-col items-end justify-center gap-2">
          <label htmlFor="views">Views</label>
          <input
            placeholder="Views"
            className="signin-inputs w-full "
            type="text"
            id="views"
            name="views"
            autoComplete="on"
            {...register("views", {
              required: true,
              minLength: 1,
            })}
          />
          {errors.views && (
            <p className="text-[12px] text-blue-900 ">
              {errors.views.type === "required" && "Please enter views"}
            </p>
          )}
          {
            //!-------server errors -----

            reopenStudentErrors &&
              reopenStudentErrors?.response?.data?.views && (
                <p className="w-full  text-[12px] text-blue-900  ">
                  {reopenStudentErrors?.response?.data?.views}
                </p>
              )
          }
        </div>
        <div className=" flex w-full flex-col items-end justify-center gap-2">
          <label htmlFor="lectures">Lectures</label>

          <select
            {...register("lecture_id", {
              required: true,
            })}
          >
            {LectureData?.data?.map((item, idx) => (
              <option key={idx} value={item?.key}>
                {item?.title}
              </option>
            ))}
          </select>
          {errors.lecture_id && (
            <p className="text-[12px] text-blue-900 ">
              {errors.lecture_id.type === "required" && "Please select Lecture"}
            </p>
          )}
          {
            //!-------server errors -----

            reopenStudentErrors &&
              reopenStudentErrors?.response?.data?.lecture_id && (
                <p className="w-full  text-[12px] text-blue-900  ">
                  {reopenStudentErrors?.response?.data?.lecture_id}
                </p>
              )
          }
        </div>

        <button className=" submit max-w-60 mt-10 " type="submit">
          {reopenStudent.flag && submitLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            "reopen"
          )}
        </button>
      </form>
    );
  }
  return (
    <section className="px-10 md:px-0 py-10 min-h-screen flex flex-col justify-center items-center">
      <ReOpenForm groupID={groupID} />
    </section>
  );
}
