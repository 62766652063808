//!------edit in table ------

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Loader } from "../../../../MainComponents";

function AddEditGroup({ edit }) {
  const navigate = useNavigate();
  const { groupID } = useParams();
  const [values, setValues] = useState(null);

  const [submitGroup, setSubmitGroup] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const api = edit
    ? process.env.REACT_APP_EDIT_GROUP_API
    : process.env.REACT_APP_ADD_GROUPS_API;
  const [addGroupSuccess, addGroupErrors, submitLoading] = useAxios(
    api,
    "POST",
    submitGroup.flag,
    submitGroup.dependency,
    submitGroup.data,
    true
  );

  const [GroupInfo, GroupInfoErrors, GroupInfoLoading] = useAxios(
    `${process.env.REACT_APP_GET_GROUP_INFO_API}/${groupID}`,
    "GET",
    groupID,
    groupID
  );

  useEffect(() => {
    if (GroupInfo) {
      let data = GroupInfo.data;

      setValues({
        ...data,
        visibility: data.visibility === 1 ? true : false,
        nextlecture: data.nextlecture === null ? 0 : data.nextlecture,
        edu_type: String(data.edu_type),
        stage: String(data.stage),
        section: data.section,
      });
    }
  }, [GroupInfo]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
  } = useForm({ mode: "onChange", values });
  const watchType = watch("edu_type");
  const watchLevel = watch("stage");

  const onSubmit = (data) => {
    setSubmitGroup({
      flag: "addGroupRequest",
      dependency: !submitGroup.dependency,
      data: {
        ...data,
        description: data?.description || null,
        id: edit ? groupID : null,
      },
    });
  };
  useEffect(() => {
    //! reset the form on success submit

    if (addGroupSuccess) {
      navigate(-1);
      reset();
    }
  }, [addGroupSuccess]);

  if (GroupInfoLoading) return <Loader />;

  return (
    <section className="flex h-full w-full flex-col items-center dark:bg-dark dark:text-light">
      <HelmetTags title="Groups | Mr Ahmed Sabry"></HelmetTags>
      <div className="form-container my-20 flex flex-col items-center">
        <h2 className="my-20 text-center text-3xl font-semibold">
          Please fill out the information to {edit ? "edit " : "add "} the group
        </h2>

        <form
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="flex h-fit w-[380px] flex-col  items-center justify-start gap-6 rounded-3xl bg-white p-10 shadow-3xl md:w-[250px] "
        >
          {/** group name */}

          <div className=" flex w-full flex-col items-end justify-center gap-2 ">
            <label className="w-full truncate" htmlFor="name">
              Group name
            </label>
            <input
              className="signin-inputs  w-full "
              type="text"
              id="name"
              placeholder="Enter group name"
              name="name"
              autoComplete="on"
              {...register("name", {
                required: true,

                maxLength: 100,
                minLength: 3,
              })}
            />
            {errors.name && (
              <p className="text-[12px] text-blue-900 ">
                {errors.name.type === "required" &&
                  "Please fill out this field"}

                {errors.name.type === "maxLength" &&
                  "The maximum number of characters is 100 characters."}
                {errors.name.type === "minLength" &&
                  "Please write at least 3 characters"}
              </p>
            )}
            {
              //!-------server errors -----

              addGroupErrors &&
                addGroupErrors?.response?.data?.errors?.name && (
                  <p className="w-full  text-[12px] text-blue-900  ">
                    {addGroupErrors?.response?.data?.errors?.name[0]}
                  </p>
                )
            }
          </div>

          {/** Stage*/}

          <div className="flex w-full flex-col  items-center  md:w-full">
            <label
              className="text-start flex items-center gap-2"
              htmlFor="Type"
            >
              Type
            </label>
            <div className="flex w-full gap-20 pb-1 sm:flex-col-reverse">
              {/** ig  */}
              <div className=" flex  items-center justify-center gap-2 ">
                <input
                  className="cursor-pointer"
                  type="radio"
                  id="ig"
                  value="0"
                  {...register("edu_type", { required: true })}
                />
                <label className="cursor-pointer text-start" htmlFor="ig">
                  IG
                </label>
              </div>

              {/** national */}
              <div className=" flex  items-center justify-center gap-2 ">
                <input
                  className="cursor-pointer"
                  type="radio"
                  id="national"
                  value="1"
                  {...register("edu_type", { required: true })}
                />
                <label className="cursor-pointer text-start" htmlFor="national">
                  National
                </label>
              </div>
            </div>
            {errors.section && (
              <p className="w-full text-start text-[12px] text-blue-900  ">
                {errors.section.edu_type === "required" &&
                  "Please fill out this field"}
              </p>
            )}

            {
              //!-------Editing server errors -----

              addGroupErrors?.response?.data?.errors &&
                addGroupErrors?.response?.data?.errors?.edu_type && (
                  <p className="w-full text-start text-[12px] text-blue-900  ">
                    {addGroupErrors?.response?.data.errors.edu_type[0]}
                  </p>
                )
            }
          </div>

          {watchType !== null && (
            <>
              <label
                className="text-start flex items-center gap-2"
                htmlFor="stage"
              >
                Stage
              </label>
              {watchType === "0" ? (
                <select
                  name="stage"
                  id="stage"
                  {...register("stage", { required: true })}
                >
                  <option value="" disabled selected>
                    Select a stage
                  </option>

                  <option value="9">Nine Year</option>
                  <option value="10">Ten Year</option>
                </select>
              ) : (
                <>
                  <select
                    name="stage"
                    id="stage"
                    {...register("stage", { required: true })}
                  >
                    <option value="" disabled selected>
                      Select a stage
                    </option>

                    <option value="22">Second preparatory</option>
                    <option value="33">Third preparatory </option>
                    <option value="1">First Secondary</option>
                    <option value="2">Second Secondary</option>
                    <option value="3">Third Secondary</option>
                  </select>
                </>
              )}
            </>
          )}
          {watchType === "1" && (
            <>
              {+watchLevel === 2 || +watchLevel === 3 ? (
                <>
                  <div className="flex flex-wrap w-full items-end gap-20 pb-1 sm:flex-col-reverse">
                    <div className=" flex  items-center justify-center gap-2 ">
                      <input
                        className="cursor-pointer"
                        type="radio"
                        id="scientific"
                        value="scientific"
                        {...register("section", {
                          required: true,
                        })}
                      />
                      <label
                        className="cursor-pointer text-start"
                        htmlFor="scientific"
                      >
                        Scientific
                      </label>
                    </div>

                    <div className=" flex  items-center justify-center gap-2 ">
                      <input
                        className="cursor-pointer"
                        type="radio"
                        id="arts"
                        value="arts"
                        {...register("section", {
                          required: true,
                        })}
                      />
                      <label
                        className="cursor-pointer text-start"
                        htmlFor="arts"
                      >
                        Arts
                      </label>
                    </div>
                    <div className=" flex  items-center justify-center gap-2 ">
                      <input
                        className="cursor-pointer"
                        type="radio"
                        id="both"
                        value="both"
                        {...register("section", {
                          required: true,
                        })}
                      />
                      <label
                        className="cursor-pointer text-start"
                        htmlFor="both"
                      >
                        Both
                      </label>
                    </div>
                  </div>
                  {errors.section && (
                    <p className="w-full text-start text-[12px] text-blue-900  ">
                      {errors.section.type === "required" &&
                        "Please fill out this field"}
                    </p>
                  )}
                </>
              ) : (
                ""
              )}
            </>
          )}
          {/** chapter description */}

          <div className=" flex w-full flex-col items-end justify-center gap-2 ">
            <label className="w-full truncate " htmlFor="description">
              Description
            </label>
            <textarea
              className=" resize-none"
              {...register("description", {
                maxLength: 155,
                minLength: 3,
              })}
              name="description"
              id="description"
              cols="40"
              rows="5"
            ></textarea>
            {errors.description && (
              <p className="text-[12px] text-blue-900 ">
                {errors.description.type === "maxLength" &&
                  "The maximum number of characters is 155 characters"}
                {errors.description.type === "minLength" &&
                  "Please write at least 3 characters"}
              </p>
            )}
          </div>
          {/** submit */}

          <button
            disabled={!isValid || (submitLoading && submitGroup.flag)}
            className=" submit mt-6 w-full "
            type="submit"
          >
            {submitLoading && submitGroup.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : edit ? (
              "Edit Class"
            ) : (
              "Add class"
            )}
          </button>
        </form>
      </div>
    </section>
  );
}

export default AddEditGroup;
